import React from 'react'
import PageHeader from '../../components/pageHeader/PageHeader'
import { connect, Dispatch } from 'react-redux'
import OptionsSummary, { ISummaryOption } from '../../components/optionsSummary/OptionsSummary'
import Summary from '../../components/summary/Summary'
import { t } from 'translations/translationFunctions'
import { providerPath, receiptPath } from '../../components/routes/paths'
import { IContractOfferRequest, IContractOfferResponse } from '@omnicar/sam-types'
import IStoreState from '../../store/IStoreState'
import { IBrandingPayloadResponse } from '@omnicar/sam-types'
import { sendContractOffer, sendOfferToOneself } from '../../api/api'
import { setPaymentInfo, ISetPaymentInfo } from '../../actions/paymentInfoActions/paymentInfoActions'
import { RouteComponentProps } from 'react-router'
import './SummaryPage.css'
import { ICalculationResponse } from '@omnicar/sam-types'
import {
  setPriceCalculation,
  ISetPriceCalculation,
} from '../../actions/priceCalculationActions/priceCalculationActions'
import { withRequiredProps } from '../RequiredPropsWrapper'
import { IContractOptionResponse, Customer } from '@omnicar/sam-types'
import {
  setOptions,
  ISetOptions,
  updateCustomerInformation,
  IUpdateCustomerInformation,
} from '../../actions/contractActions/contractActions'
import { resetReduxStore, IResetReduxStore } from '../../actions/fullStoreActions/fullStoreActions'
import { IProviderInformationResponse } from '@omnicar/sam-types'
import PaymentSection from '../../components/paymentSection/PaymentSection'
import Spinner from 'react-spinkit'
import { resolveProviderLocale } from '../../utils/locale'
import { isOutageError } from 'utils/outage'
import { showError, showSuccess } from 'utils/toastify'

interface ISummaryPageReduxProps {
  contractOffer: IContractOfferRequest
  priceCalculation: ICalculationResponse
  selectedOptions: IContractOptionResponse[]
  availableOptions: IContractOptionResponse[]
  paymentInformation: IContractOfferResponse
  providerData: IBrandingPayloadResponse
  providerInfo: IProviderInformationResponse
  customer: Customer | undefined
}

interface ISummaryPageReduxDispatch {
  setPaymentInfo: (paymentInfo: IContractOfferResponse) => ISetPaymentInfo
  setPriceCalculation: (calculation: ICalculationResponse) => ISetPriceCalculation
  updateCustomerInformation: (customerInfo: Partial<Customer>) => IUpdateCustomerInformation
  setOptions: (optionsSelected: IContractOptionResponse[]) => ISetOptions
  resetReduxStore: () => IResetReduxStore
}

interface ISummaryPageState {
  confirmedTermsOfService: boolean
  confirmedTermsOfTrade: boolean
  isCalculating: boolean
  isLoadingNext: boolean
  options: ISummaryOption[]
  isLoadingContractOffer: boolean
  sendOfferButtonDisabled: boolean
}

class SummaryPage extends React.Component<
  ISummaryPageReduxProps & ISummaryPageReduxDispatch & RouteComponentProps<any>,
  ISummaryPageState
> {
  public state: ISummaryPageState

  constructor(props: ISummaryPageReduxProps & ISummaryPageReduxDispatch & RouteComponentProps<any>) {
    super(props)
    this.state = {
      confirmedTermsOfService: false,
      confirmedTermsOfTrade: false,
      isCalculating: false,
      isLoadingNext: false,
      options: this.mapOptions(props),
      isLoadingContractOffer: true,
      sendOfferButtonDisabled: false,
    }
  }

  public async componentDidMount() {
    const { contractOffer, setPaymentInfo } = this.props
    const createContractData = await sendContractOffer(contractOffer, true)

    if (!createContractData.data) {
      if (!isOutageError(createContractData.errorData)) {
        showError(t('An error occurred'))
      }
      return
    }

    setPaymentInfo(createContractData.data)
    this.setState({
      isLoadingContractOffer: false,
    })
  }

  public render() {
    const { options, isLoadingContractOffer } = this.state
    const { contractOffer, paymentInformation, providerData } = this.props
    const { customer, product } = contractOffer

    return (
      <section className="SummaryPage">
        <PageHeader pageTitle="summary" />
        <div className="page-content">
          <div className="row">
            <div className="col-sm-10 offset-sm-1">
              <div className="box-summary summary">
                <div className="row">
                  <div className="col-md-6">
                    <h3 className="mb-3 summary-heading pullUp">{t('Customer and vehicle information')}</h3>
                    <Summary className="mb-3" customer={customer} />
                    <Summary
                      product={product}
                      startMileage={
                        contractOffer.template!.calculationMethod === 200 ? contractOffer.startMileage : null
                      }
                    />
                    <br />
                  </div>
                  <div className="col-md-6">
                    <h3 className="mb-3 pullUp">{t('Subscription Contract Provider')}</h3>
                    <Summary provider={this.props.providerData} />

                    <h3>{t('Subscription Contract')}</h3>
                    <Summary className="mt-sm" contract={contractOffer} />
                    <br />
                    <button
                      type="button"
                      className="btn sam-btn-primary mr-4"
                      data-e2e="Send-offer-button"
                      onClick={this.sendOffer}
                      disabled={this.state.sendOfferButtonDisabled}>
                      <span className="fa fa-send pr-2" />
                      {t('Send this Offer to my Email')}
                    </button>
                    <br />
                    <br />
                    <a className="d-print-none summary-link" href="" onClick={this.printOffer}>
                      <span className="text-bolder">{t('Print offer')}</span>
                    </a>
                    <br />
                    {contractOffer.template!.termsOfService.ref && (
                      <a
                        className="d-print-none summary-link"
                        href={contractOffer.template!.termsOfService.ref}
                        target="_blank"
                        rel="noopener noreferrer">
                        <strong className="text-bolder">{t('Terms & conditions')} (PDF)</strong>
                      </a>
                    )}
                  </div>
                </div>
              </div>
              {options.length > 0 && (
                <div className="box-summary options-summary-container mt-md-3 pullUp">
                  <OptionsSummary options={options} />
                </div>
              )}
              {paymentInformation && !isLoadingContractOffer ? (
                <PaymentSection
                  className="summary-payment-section"
                  onBack={this.prevCallback}
                  onNext={this.nextCallback}
                  contractId={paymentInformation.contractId}
                  token={paymentInformation.token}
                  locale={resolveProviderLocale(providerData && providerData.locale)}
                />
              ) : (
                <Spinner className="d-print-none payment-section__spinner" name="circle" color="black" fadeIn="none" />
              )}
            </div>
          </div>
        </div>
      </section>
    )
  }

  private mapOptions = (props: ISummaryPageReduxProps): ISummaryOption[] =>
    props.contractOffer.options
      .map((option) => ({ option, selected: true, readOnly: true }))
      .sort((a, b) => (a.readOnly && !b.readOnly ? -1 : 1))

  private sendOffer = async () => {
    const { prettyIdentifier } = this.props.paymentInformation
    const res = await sendOfferToOneself('' + prettyIdentifier)

    if (res && res.statusCode && res.statusCode === 200) {
      showSuccess(t('The offer has been sent'))
      this.setState({ sendOfferButtonDisabled: true })
    } else {
      const debug = res && res.errorData && (res as any).errorData.debug
      const errorMessage = (debug && debug.errorMessage) || ''

      let html: string = ''
      html += `${t('An error occurred, try again later')}`
      html += !errorMessage ? '' : errorMessage

      showError(html)
      console.error(res)
    }
  }

  private printOffer = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault()
    window.print()
  }

  private prevCallback = async () => this.props.history.goBack()

  private nextCallback = () => this.props.history.push(providerPath(receiptPath))
}

function mapStateToProps(state: IStoreState): ISummaryPageReduxProps {
  return {
    contractOffer: state.contractOffer,
    priceCalculation: state.priceCalculation!,
    selectedOptions: state.contractOffer.options,
    availableOptions: state.contractData.options,
    paymentInformation: state.paymentInfo!,
    providerData: state.providerData!,
    providerInfo: state.providerData!.providerInformation,
    customer: state.contractOffer.customer,
  }
}

function mapDispatchToProps(dispatch: Dispatch<IStoreState>): ISummaryPageReduxDispatch {
  return {
    setPaymentInfo: (paymentInfo: IContractOfferResponse) => dispatch(setPaymentInfo(paymentInfo)),
    setPriceCalculation: (calculation: ICalculationResponse) => dispatch(setPriceCalculation(calculation)),
    setOptions: (optionsSelected: IContractOptionResponse[]) => dispatch(setOptions(optionsSelected)),
    resetReduxStore: () => dispatch(resetReduxStore()),
    updateCustomerInformation: (customerInfo: Partial<Customer>) => dispatch(updateCustomerInformation(customerInfo)),
  }
}

const isStateComplete = (s: IStoreState) => !!(s.contractOffer && s.contractOffer.customer)

const wrappedComponent = withRequiredProps(isStateComplete)(SummaryPage)
export default connect(mapStateToProps, mapDispatchToProps)(wrappedComponent as any)
